import React, { useMemo, useState } from 'react';
import { Button, Col, Row, Segmented, Space } from 'antd';
import { Content } from 'antd/es/layout/layout';

import { RequestTable } from '@app/components/tables/request_table';
import { RequestModal } from '@app/components/modals/request_modal';
import { i18n } from '@app/translations/i18n.config';
import { AppHeader } from '@app/components/app_header';
import { useAuth } from '@app/auth/auth-context';
import { isAddRequestActionAllowed, isCancelledRequestsAllowed } from '@app/utils/permissions/permissions';

import { ReactComponent as PlusIcon } from '@app/assets/icons/plus.svg';
import { DashboardTabs } from '@app/types';

export const Dashboard = () => {
  const { userRoles } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(DashboardTabs.Requests);

  const isActionAllowed = isAddRequestActionAllowed(userRoles);
  const isCancelledAllowed = isCancelledRequestsAllowed(userRoles);

  const requestTabOptions = useMemo(() => {
    const options = [
      {
        value: DashboardTabs.Requests,
        label: i18n.t(`request.tabs.${DashboardTabs.Requests}`),
      },
    ];

    if (isActionAllowed) {
      options.push({
        value: DashboardTabs.Drafts,
        label: i18n.t(`request.tabs.${DashboardTabs.Drafts}`),
      });
    }

    if (isCancelledAllowed) {
      options.push({
        value: DashboardTabs.Cancelled,
        label: i18n.t(`request.tabs.${DashboardTabs.Cancelled}`),
      });
    }

    return options;
  }, [isActionAllowed, isCancelledAllowed]);

  const segmentedChangeHandler = (value: any) => {
    setCurrentTab(value);
  };

  return (
    <>
      <AppHeader pageTitle={i18n.t('pageTitles.dashboard')} />

      <Content className={'mx-2 mt-2 p-4'}>
        <Row className={'h-100'}>
          <Col span={24} className={'h-100 mb-3'}>
            <Space className={'w-100 justify-content-between'}>
              <Segmented
                defaultValue={currentTab}
                onChange={segmentedChangeHandler}
                options={requestTabOptions}
                label={i18n.t(`request.tabs.${currentTab}`)}
              />

              {isActionAllowed && (
                <Button icon={<PlusIcon />} size={'small'} type={'default'} onClick={() => setIsModalOpen(true)}>
                  {i18n.t('request.new')}
                </Button>
              )}
            </Space>

            <Content className={'sub-content-white p-2 rounded-top-0'} style={{ minHeight: '50%' }}>
              <RequestTable currentTab={currentTab} />
            </Content>
          </Col>
          {isModalOpen && <RequestModal open={isModalOpen} footer={false} onCancel={() => setIsModalOpen(false)} />}
        </Row>
      </Content>
    </>
  );
};
