export enum RefundRequestStatusesEnum {
  Draft = 'draft',

  // user_FA
  NotReceived = 'not_received',
  ModifyRequest = 'modify_request',
  ModifyRequestInProgress = 'modify_request_in_progress',
  RequestReSubmitted = 'request_re_submitted',

  // admin_FA
  InProcess = 'in_process',
  ApprovedByAdmin = 'approved_by_admin',
  ReturnedForVerification = 'returned_for_verification',
  RefundReadyForPayment = 'refund_ready_for_payment',

  // audit_FA
  WaitingForApprovalByAuditor = 'waiting_for_approval_by_auditor',
  ApprovedByAuditor = 'approved_by_auditor',

  // planing_FA
  RefundPaid = 'refund_paid',
  ApprovedByPlanningTeam = 'approved_by_planning_team',

  //
  Cancelled = 'cancelled',
  Done = 'done',
}
