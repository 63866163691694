export const coinDenomination = [0.25, 0.5, 1];
export const defaultState = {
  '0.5': 0,
  '0.25': 0,
  '1': 0,
  '5': 0,
  '10': 0,
  '20': 0,
  '50': 0,
  '100': 0,
  '200': 0,
  '500': 0,
  '1000': 0,
};
export const minTotalToShowMessage = 5000;
