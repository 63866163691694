export const bytesToMegabytes = (bytes: number): number => parseFloat((bytes / (1024 * 1024)).toFixed(3));

export const formatSafeboxState = (state) => {
  return Object.entries(state)
    .map(([denomination, quantity]) => ({
      denomination: Number(denomination),
      quantity: Number(quantity),
      amount: Number(denomination) * Number(quantity),
    }))
    .sort((a, b) => b.denomination - a.denomination);
};
export const parseSafeboxState = (array) => {
  return array.reduce((acc, item) => {
    acc[item.denomination] = item.quantity;
    return acc;
  }, {});
};
