import {RefundRequestStatusesEnum, Request, StatusCategoryEnum, StatusEntity, UserRolesEnum} from '@app/types';

export const isEditRequestActionsAllowed = (
  userRoles: Array<string>,
  status: StatusEntity,
  isOwner: boolean,
): boolean => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  if (isAppAdmin) {
    switch (status.key) {
      case RefundRequestStatusesEnum.Done:
      case RefundRequestStatusesEnum.Cancelled:
        return false;
      default:
        return true;
    }
  }

  if (status.statusCategory === StatusCategoryEnum.ToDo && status.key !== RefundRequestStatusesEnum.Draft) {
    return false;
  }

  switch (status.key) {
    case RefundRequestStatusesEnum.Draft:
    case RefundRequestStatusesEnum.ModifyRequestInProgress:
      return isUser || isOwner || isAdmin;
    case RefundRequestStatusesEnum.InProcess:
      return isAdmin && !isOwner;
    case RefundRequestStatusesEnum.ReturnedForVerification:
      return isAdmin && !isOwner;
    default:
      return false;
  }
};

export const isCancelledRequestsAllowed = (userRoles: Array<string>) => {
  return userRoles.includes(UserRolesEnum.AppAdmin);
};

export const isAddCommentActionAllowed = (userRoles: Array<string>) => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isUser || isAdmin || isAuditor || isPlanning || isAppAdmin;
};

export const isShowReceiptCategory = (userRoles: Array<string>) => {
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isAdmin || isAuditor || isPlanning || isAppAdmin;
};

export const isShowDownloadReceiptCheckboxes = (userRoles: Array<string>) => {
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isPlanning || isAppAdmin;
};

export const isShowVerifyReceiptCheckboxes = (userRoles: Array<string>, statusKey: RefundRequestStatusesEnum) => {
  const isPlanning = userRoles.includes(UserRolesEnum.Admin);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);
  const forbiddenStatuses = [
    RefundRequestStatusesEnum.Draft,
    RefundRequestStatusesEnum.RefundPaid,
    RefundRequestStatusesEnum.Done,
  ];

  if (forbiddenStatuses.includes(statusKey)) {
    return false;
  }

  return isPlanning || isAppAdmin;
};

export const isChangeCategoryForAuditorAllowed = (
  userRoles: Array<string>,
  statusKey: RefundRequestStatusesEnum | string,
) => {
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);
  const isAllowedStatus = statusKey === RefundRequestStatusesEnum.WaitingForApprovalByAuditor;

  return (isAuditor || isAppAdmin) && isAllowedStatus;
};

export const isAddRequestActionAllowed = (userRoles: Array<string>) => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isUser || isAdmin || isAppAdmin;
};

export const isSafeBoxesAllowed = (userRoles: Array<string>) => {
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isPlanning || isAppAdmin;
};

export const isRefundAllowed = (userRoles: Array<string>, statusKey: RefundRequestStatusesEnum | string) => {
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);
  const isAllowedStatus = statusKey === RefundRequestStatusesEnum.RefundReadyForPayment;

  return (isAdmin || isAppAdmin) && isAllowedStatus;
};

export const isStayOnRequestPage = (userRoles: Array<string>, statusCategory: StatusCategoryEnum | string) => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);
  const isAllowedCategory = statusCategory === StatusCategoryEnum.ToDo;

  return (isUser || isAdmin || isAuditor || isAppAdmin) && isAllowedCategory;
};

export const isShowStartButton = (userRoles: Array<string>, request: Request, isDrafts: boolean) => {
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  if (request?.status?.statusCategory !== StatusCategoryEnum.ToDo || isDrafts) {
    return false;
  }

  if (
    isAppAdmin &&
    request?.status?.statusCategory === StatusCategoryEnum.ToDo &&
    !isDrafts
  ) {
    return true;
  }

  const roleConditions = {
    [UserRolesEnum.User]: [RefundRequestStatusesEnum.ModifyRequest],
    [UserRolesEnum.Admin]: [
      RefundRequestStatusesEnum.ModifyRequest,
      RefundRequestStatusesEnum.NotReceived,
      RefundRequestStatusesEnum.ReturnedForVerification,
      RefundRequestStatusesEnum.RequestReSubmitted,
      RefundRequestStatusesEnum.ApprovedByAuditor,
    ],
    [UserRolesEnum.Auditor]: [RefundRequestStatusesEnum.ApprovedByAdmin],
    [UserRolesEnum.Planning]: [RefundRequestStatusesEnum.RefundPaid],
  };

  return userRoles.some((role) => roleConditions[role]?.includes(request.status.key));
};
