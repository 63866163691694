import { ToastContainerProps } from 'react-toastify';

export const EMPTY_VALUE_REPRESENTATION = '---';

export const IMPORT_FILES_ACCEPT_STRING = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
].join(',');

export const MAX_FILE_SIZE_MB = 150;

export const MAX_PREVIEW_FILE_SIZE_MB = 5;

export const FILES_ACCEPT_STRING = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
].join(',');

export const MAX_COMMENT_CONTENT_LENGTH = 130;

export const TOAST_CONFIG: ToastContainerProps = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: 'light',
  style: {
    width: 'max-content',
    maxWidth: '50%',
    minWidth: '300px',
  },
};
