import React, { FC } from 'react';
import dayjs from 'dayjs';
import { Tag, Typography } from 'antd';
import { useQuery } from 'react-query';

import { i18n } from '@app/translations/i18n.config';
import { getHistoryLast } from '@app/api';

import { ReactComponent as ClockIcon } from '@app/assets/icons/clock_icon.svg';
import { RQContent } from '@app/components/rq_content';
import { Tools } from '@app/utils/tools';
import { EMPTY_VALUE_REPRESENTATION } from '@app/config/constants';

interface UpdatesLabelProps {
  requestId?: string;
  updatedAt?: string;
  className?: string;
}

export const UpdatesLabel: FC<UpdatesLabelProps> = ({ requestId, updatedAt, className }) => {
  const { status: historyStatus, data: historyData } = useQuery({
    queryKey: [requestId],
    queryFn: () => getHistoryLast(requestId),
  });

  return (
    <RQContent status={historyStatus}>
      {Tools.isPresent(historyData) && (
        <Typography.Title level={5} className={className}>
          <ClockIcon width={12} height={12} style={{ verticalAlign: 'sub', margin: '0 3px' }} />
          {i18n.t('request.updateLabel', {
            updateOwner: historyData?.userName ?? EMPTY_VALUE_REPRESENTATION,
            date: dayjs(updatedAt).format('HH:mm MM.DD.YYYY') ?? EMPTY_VALUE_REPRESENTATION,
            role: historyData?.userRoles ? Tools.humanize(historyData?.userRoles.join()) : null,
          })}
        </Typography.Title>
      )}
    </RQContent>
  );
};
