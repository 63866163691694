import React, { useState } from 'react';
import { Content } from 'antd/es/layout/layout';
import { Button, Card, Col, Empty, Row, Space, Tag, Typography } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';

import { AppHeader } from '@app/components/app_header';
import { i18n } from '@app/translations/i18n.config';
import { SafeBoxTable } from '@app/components/tables/safebox_table';
import { getSafeboxes, updateSafeboxState } from '@app/api/safeboxes';
import { RQContent } from '@app/components/rq_content';
import { Tools } from '@app/utils/tools';
import { parseSafeboxState } from '@app/utils/calculations/calculations';
import { minTotalToShowMessage } from '@app/components/tables/safebox_table/constants';

import { ReactComponent as EditIcon } from '@app/assets/icons/edit.svg';

export const SafeBox = () => {
  const queryClient = useQueryClient();

  const [editBoxId, setEditBoxId] = useState(null);

  const { status: safeboxesStatus, data: safeboxes } = useQuery({
    queryKey: ['safeboxes'],
    queryFn: () => getSafeboxes(),
  });

  const { mutate: saveStateMutate, isLoading: isSaveStateLoading } = useMutation({
    mutationFn: (data: any) => updateSafeboxState(data.id, data.payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries('safeboxes');
      setEditBoxId(null);
      toast.success(i18n.t('messages.success.safeboxUpdate'));
    },
  });

  const saveHandler = (id, tableData) => {
    const data = {
      id,
      payload: {
        state: parseSafeboxState(tableData),
      },
    };
    saveStateMutate(data);
  };

  return (
    <>
      <AppHeader pageTitle={i18n.t('pageTitles.safeboxManagement')} />

      <Content className={'mx-2 mt-2 p-4'}>
        <RQContent status={safeboxesStatus}>
          <Row gutter={24}>
            {Tools.isBlank(safeboxes) ? (
              <Empty className={'w-100'} />
            ) : (
              safeboxes?.map((s) => (
                <Col lg={8} md={12} className={'mb-3'} key={s.id}>
                  <Card
                    bordered={false}
                    title={
                      <Space className={'w-100 justify-content-between flex-wrap'}>
                        <Typography.Title level={3}>{s.name}</Typography.Title>
                        {s.totalAmount < minTotalToShowMessage && (
                          <Tag color={'gold'}>
                            {i18n.t('refund.lessTotal')}
                            <NumericFormat displayType={'text'} value={minTotalToShowMessage} thousandSeparator={','} />
                          </Tag>
                        )}
                        {!editBoxId && (
                          <Button
                            type={'dashed'}
                            size={'small'}
                            className={'collapsed'}
                            onClick={() => setEditBoxId(s.id)}
                            icon={<EditIcon width={20} height={20} />}
                          />
                        )}
                      </Space>
                    }
                  >
                    <SafeBoxTable
                      isLoading={isSaveStateLoading}
                      safebox={s}
                      isEditMode={editBoxId === s.id}
                      onDiscard={() => setEditBoxId(null)}
                      onSave={(data) => saveHandler(s.id, data)}
                    />
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </RQContent>
      </Content>
    </>
  );
};
