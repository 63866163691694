import { RequestStatusType } from '@app/types/statuses';

export enum RequestTransitionCommands {
  Submit = 'SUBMIT',
  Reject = 'REJECT',
  Cancel = 'CANCEL',
}

export type RequestTransitionAction = {
  title: string;
  command: RequestTransitionCommands | string;
  confirmation: string;
  isCommentMandatory: boolean;
};

export type Request = {
  id: string;
  recipientId: string;
  requestId: number;
  request: {
    receipts: any;
    totalTax: number;
    totalAmount: number;
    totalTaxedAmount: number;
  };
  creatorId: string;
  departmentNumber: string;
  createdAt: string;
  updatedAt: string;
  status: RequestStatusType;
};

export type VerifyReceipt = {
  id: string;
  isVerified: boolean;
};

