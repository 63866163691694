import React from 'react';
import { Card, Table, Typography } from 'antd';

import { i18n } from '@app/translations/i18n.config';
import { RQContent } from '@app/components/rq_content';
import { EMPTY_VALUE_REPRESENTATION } from '@app/config/constants';

export const EmployeeTable = ({ requester }) => {
  const columns = [
    {
      title: i18n.t('employee.tableTitle.email'),
      render: () => requester.email ?? EMPTY_VALUE_REPRESENTATION,
    },
    {
      title: i18n.t('employee.tableTitle.requesterName'),
      render: () => requester.name ?? EMPTY_VALUE_REPRESENTATION,
    },
    {
      title: i18n.t('employee.tableTitle.employeeNumber'),
      render: () => requester.employeeNumber ?? EMPTY_VALUE_REPRESENTATION,
    },
    {
      title: i18n.t('employee.tableTitle.departmentNumber'),
      render: () => requester.departmentNumber ?? EMPTY_VALUE_REPRESENTATION,
    },
  ];

  return (
    <RQContent status={'success'}>
      <Card bordered={false} title={<Typography.Title level={3}>{i18n.t('employee.info')}</Typography.Title>}>
        <Table rowClassName={'without-bg'} dataSource={[{}]} pagination={false} columns={columns} />
      </Card>
    </RQContent>
  );
};
