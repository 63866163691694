import React, { FC } from 'react';
import { Tag } from 'antd';

import { RequestStatusType } from '@app/types/statuses';
import { useAuth } from '@app/auth/auth-context';
import { RefundRequestStatusesEnum, StatusCategoryEnum, UserRolesEnum } from '@app/types';
import { Tools } from '@app/utils/tools';
import { i18n } from '@app/translations/i18n.config';

interface RequestStatusProps {
  status: RequestStatusType;
  isToastMode?: boolean;
}

export const RequestStatus: FC<RequestStatusProps> = ({ status, isToastMode }) => {
  const { userRoles } = useAuth();
  const isRoleUser = userRoles?.includes(UserRolesEnum.User);

  const inProcessStatuses = [
    RefundRequestStatusesEnum.ApprovedByAdmin,
    RefundRequestStatusesEnum.ApprovedByAuditor,
    RefundRequestStatusesEnum.ApprovedByPlanningTeam,
    RefundRequestStatusesEnum.ReturnedForVerification,
    RefundRequestStatusesEnum.InProcess,
    RefundRequestStatusesEnum.WaitingForApprovalByAuditor,
    RefundRequestStatusesEnum.RefundReadyForPayment,
  ];
  const isInProcessStatusForUserRole = isRoleUser && inProcessStatuses.includes(status.key);

  const getStatusColor = (key, category) => {
    if (isInProcessStatusForUserRole) {
      return 'blue';
    }

    if (key === RefundRequestStatusesEnum.Cancelled) {
      return 'red';
    }

    switch (category) {
      case StatusCategoryEnum.ToDo:
        return 'volcano';
      case StatusCategoryEnum.InProgress:
        return 'blue';
      case StatusCategoryEnum.Done:
        return 'green';
      default:
        return 'volcano';
    }
  };

  if (Tools.isBlank(userRoles) && !isToastMode) return;

  return (
    <Tag color={getStatusColor(status.key, status.statusCategory)} style={{ textTransform: 'uppercase' }}>
      {isInProcessStatusForUserRole ? i18n.t('request.inProcess') : status.name}
    </Tag>
  );
};
