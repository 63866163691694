import React, { FC, useRef, useState } from 'react';
import { Button, Checkbox, Form, Space } from 'antd';

import { i18n } from '@app/translations/i18n.config';
import TextArea from 'antd/es/input/TextArea';
import { CommentPayload } from '@app/types/comments';
import { useAuth } from '@app/auth/auth-context';
import { UserRolesEnum } from '@app/types';
import { Tools } from '@app/utils/tools';

interface CommentFormProps {
  onBack: () => void;
  onCommentAdded: (data: CommentPayload) => void;
  isAddPaymentLoading: boolean;
}

export const CommentForm: FC<CommentFormProps> = ({ onBack, onCommentAdded, isAddPaymentLoading }) => {
  const { userRoles } = useAuth();
  const inputRef = useRef(null);

  const [commentValue, setCommentValue] = useState(null);
  const [showToEmployee, setShowToEmployee] = useState(true);

  const addCommentHandler = () => {
    const payload = {
      title: '',
      body: commentValue,
      showToEmployee,
    };
    onCommentAdded(payload);
  };

  return (
    <Form layout={'vertical'} className={'p-2'}>
      <Form.Item label={i18n.t('comments.addComment')}>
        <TextArea
          style={{ maxHeight: '250px' }}
          ref={inputRef}
          value={commentValue}
          onChange={(e) => setCommentValue(e.target.value)}
        />
      </Form.Item>
      <Space size={'large'} className={'w-100 mt-5 justify-content-center'} direction={'horizontal'}>
        {!userRoles.includes(UserRolesEnum.User) && (
          <Checkbox defaultChecked checked={showToEmployee} onChange={(e) => setShowToEmployee(e.target.checked)}>
            {i18n.t('comments.showToEmployeeLabel')}
          </Checkbox>
        )}
        <Button
          type={'primary'}
          disabled={Tools.isBlank(commentValue) || isAddPaymentLoading}
          loading={isAddPaymentLoading}
          onClick={addCommentHandler}
        >
          {i18n.t('refund.confirmPayment')}
        </Button>
        <Button type={'default'} onClick={onBack} disabled={isAddPaymentLoading}>
          {i18n.t('refund.stepBack')}
        </Button>
      </Space>
    </Form>
  );
};
