import axiosInstance from '@app/config/api/config';

export async function getSafeboxes() {
  const res = await axiosInstance.get('safeboxes');

  return res.data;
}

export async function getSafeboxState(safeboxId: string) {
  const res = await axiosInstance.get(`safeboxes/${encodeURIComponent(safeboxId)}/state`);

  return res.data;
}

export async function updateSafeboxState(safeboxId: string, payload) {
  const res = await axiosInstance.post(`safeboxes/${encodeURIComponent(safeboxId)}/state`, payload);

  return res.data;
}
